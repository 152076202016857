import {OrganizationTo} from './organizationTo';

export class UserTo {
    userName: string;
    email: string;
    firstName: string;
    lastName: string;
    org: OrganizationTo;
    uuid: number;
    roles: string[];
    phone: string;
    useMFA: boolean;
    ssoUser: boolean;
    smsConsent: boolean;


    constructor(object?: any) {
        this.userName = object.userName;
        this.email = object.email;
        this.firstName = object.firstName;
        this.lastName = object.lastName;
        if (object.hasOwnProperty('org') && object.org) {
            this.org = new OrganizationTo(object.org);
        }
        this.uuid = object.uuid;
        this.roles = object.roles;
        this.phone = object.phone;
        this.useMFA = object.useMFA;
        this.ssoUser = object.ssoUser;
        this.smsConsent = object.smsConsent;
    }

}
