import {Injectable} from "@angular/core";
import {ConfigService} from '../../assets/config/config-svc.service';
import { HttpClient } from '@angular/common/http';
import {SharedService} from '../shared/shared.service';
import {Alerts} from '../shared/enums/alerts';
import { SSE } from 'sse.js';

@Injectable({
    providedIn: 'root'
})
export class ZeroTouchService {

    constructor(public http: HttpClient, 
                public sharedService: SharedService) {
    }

    callStream(callback) {
        const url = `${ConfigService.config.brokerServiceUrl}/call-stream`;
        const source = new SSE(url, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem(this.sharedService.ID_TOKEN),
                'Content-Type': 'application/json'
            },
            withCredentials: true }
        );
        source.addEventListener('EOD', e => {
            source.close();
        });
        source.addEventListener('error', e => {
            console.error(e);
            let statusRegEx = /^[4-5]\d{2}$/
            if (this.sharedService.isTokenExpired()) {
                this.sharedService.handleTokenExpiration(this.sharedService.tokenExpiryTitle, this.sharedService.tokenExpiryMsg);
            } else if (e.source?.xhr?.status === 401) {
                this.sharedService.handleTokenExpiration(this.sharedService.tokenInvalidTitle, this.sharedService.tokenInvalidMsg);
            } else if (statusRegEx.test(e.source?.xhr?.status)) {
                this.sharedService.showAlertNoTimer(Alerts.ERROR, 'Unable to retrieve active calls');
            }
            source.close();
        });
        source.addEventListener('message', e => {
            let result = JSON.parse(e["data"]);
            callback(result.data);
        });
        source.stream();
        return source;
    }
}