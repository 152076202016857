import { Component, OnInit } from '@angular/core';
import { ZeroTouchService } from './zero-touch.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Patterns } from '../shared/enums/patterns';
import { CookieService } from '../shared/cookie.service';

@Component({
    selector: 'app-zero-touch',
    templateUrl: './zero-touch.component.html',
    styleUrls: ['./zero-touch.component.scss']
})
export class ZeroTouchComponent implements OnInit {

    stationId = new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(30), Validators.pattern(Patterns.ALPHANUMERIC)]);
    securityWord: string;
    tn: string;
    drawerOpened: boolean = true;
    isConnected: boolean = false;
    isCallTerminated: boolean = false;
    eventSource = null;

    readonly STATION_ID = 'stationId';

    constructor(private zeroTouchService: ZeroTouchService,
                private cookieService: CookieService) { 

    }

    ngOnInit(): void {
        let stationId = this.cookieService.getCookie(this.STATION_ID);
        if(stationId) {
            this.stationId.setValue(stationId);
        }
    }

    ngOnDestroy(): void {
        if(this.eventSource && this.eventSource.readyState !== this.eventSource.CLOSED) {
            this.eventSource.close();
            this.eventSource = null;
        }
    }

    connect(): void {
        if (this.isNotValidData()) {
            return;
        }
        if(this.stationId.value) {
            this.cookieService.setCookie( {name: this.STATION_ID, value: this.stationId.value} );
            this.isConnected = true;

            this.eventSource?.close();
            this.eventSource = this.zeroTouchService.callStream(callData => {
                if (callData.data.positionId === this.stationId.value && callData.data.securityWord) {
                    try {
                        if (callData.status === "DELETE" && this.securityWord === callData.data.securityWord) {
                            this.isCallTerminated = true;
                        } else if (callData.status === "ADD") { 
                            this.clearResult();
                            setTimeout(() => {
                                this.securityWord = callData.data.securityWord;
                                this.tn = callData.data.tn;
                            }, 500);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                }
            });
        }
    }

    disconnect(): void {
        if(this.eventSource && this.eventSource.readyState !== this.eventSource.CLOSED) {
            this.eventSource.close();
        }
        this.eventSource = null;
        this.isConnected = false;
        this.clearResult();
    }

    clearResult(): void {
        this.securityWord = undefined;
        this.isCallTerminated = false;
    }

    toggle(): void {
        this.drawerOpened = !this.drawerOpened;
        let target = document.getElementById('stationIdContainer');
        if (target) {
            if (this.drawerOpened) {
                target.style.height = target.scrollHeight+"px";
            } else {
                target.style.height = "0px";
            }
        }
    }

    getErrorMessage(formControl: any): string {
        if (formControl === this.STATION_ID) {
            return this.stationId.hasError('required') ? 'You must enter a value' : 'Not a valid Position Id';
        }
    }

    markFormControlsAsTouched(): void {
        this.stationId.markAsTouched();
    }

    isNotValidData(): boolean {
        this.markFormControlsAsTouched();  
        return this.stationId.invalid;
    }

    formatTn(number: string): string {
        return '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6, 10);
    }
    
}
