import { HttpRequestEnum } from '../enums/HttpRequestEnum';
import { AuthType } from '../enums/authType';
import { ProviderType } from '../enums/provider-type.enum';
import { RequestHeaderTo } from './requestHeaderTo';
import { SupplementalDataProviderTo } from './supplementalDataProviderTo';

export class ProviderTo {
    name: string;
    type: ProviderType;
    searchTypeName: string;
    dataFoundEndpointUrl: string;
    endpointUrl: string;
    httpMethod: HttpRequestEnum;
    requestTemplate: any;
    requestHeaders: RequestHeaderTo[];
    authType: AuthType;
    additionalDataEndpointUrl: string;
    additionalDataHttpMethod: HttpRequestEnum;
    additionalDataRequestTemplate: any;
    username: string;
    password: string ;
    enabled: boolean;
    sensitiveData: boolean;
    clientIdPref: string;
    clientSecretPref: string;
    oauth2TokenEndpointUrl: string;
    clientId: string;
    clientSecret: string;
    audience: string;
    oauth2TokenHeaders: RequestHeaderTo[];
    credentialsPerPSAP: boolean;
    supplementalData: boolean;
    supplementalDataProviders: SupplementalDataProviderTo[];

    constructor(object?: any) {
        if (object) {
            this.name = object.name;
            this.type = object.type;
            this.searchTypeName = object.searchTypeName;
            this.dataFoundEndpointUrl = object.dataFoundEndpointUrl;
            this.endpointUrl = object.endpointUrl;
            this.httpMethod = object.httpMethod;
            this.requestTemplate = object.requestTemplate;
            this.requestHeaders = [];
            object.requestHeaders?.forEach(header => {
                this.requestHeaders.push(new RequestHeaderTo(header));
            });
            this.additionalDataEndpointUrl = object.additionalDataEndpointUrl;
            this.additionalDataHttpMethod = object.additionalDataHttpMethod;
            this.additionalDataRequestTemplate = object.additionalDataRequestTemplate;
            this.authType = object.authType;
            this.username = object.username;
            this.password = object.password;
            this.enabled = object.enabled;
            this.sensitiveData = object.sensitiveData;
            this.clientIdPref = object.clientIdPref;
            this.clientSecretPref = object.clientSecretPref;
            this.oauth2TokenEndpointUrl = object.oauth2TokenEndpointUrl;
            this.clientId = object.clientId;
            this.clientSecret = object.clientSecret;
            this.audience = object.audience;
            this.oauth2TokenHeaders = [];
            object.oauth2TokenHeaders?.forEach(header => {
                this.oauth2TokenHeaders.push(new RequestHeaderTo(header));
            });
            this.credentialsPerPSAP = object.credentialsPerPSAP;
            this.supplementalData = object.supplementalData;
            this.supplementalDataProviders = [];
            object.supplementalDataProviders?.forEach(element => {
                this.supplementalDataProviders.push(new SupplementalDataProviderTo(element));
            });
        }
    }
}